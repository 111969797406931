import { useState } from "react";
import { DeleteModal } from "../../../components/modal/DeleteModal.tsx";
import { DeleteResourceModalContent } from "../../../components/modal/DeleteResourceModal.tsx";
import { useStandardMutation } from "../../api/useStandardMutation.ts";
import * as ipQuery from "../ipAddress/apiOperations.ts";
import type { LoadBalancerWithAssigned } from "../ipAddress/joinAssignedQuery.ts";
import type { DeleteLoadBalancerMutation } from "./LoadBalancerView.tsx";

export function LoadBalancerDeleteModal({
    item,
    deleteMutation,
}: {
    item: LoadBalancerWithAssigned;
    deleteMutation: DeleteLoadBalancerMutation;
}) {
    //#region Hooks
    const [deleteFloatingIp, setDeleteFloatingIp] = useState<string | undefined>(undefined);
    const deleteIpAddressMutation = useStandardMutation(ipQuery.deleteIpAddressMutation);
    //#endregion

    const { location, uuid, assignedPublicIp } = item;

    async function onDelete() {
        await deleteMutation.mutateAsync({ location, uuid });
        if (deleteFloatingIp === "yes") {
            await deleteIpAddressMutation.mutateAsync({ location, address: assignedPublicIp!.address });
        }
    }

    return (
        <DeleteModal
            title="Delete Load Balancer"
            isActionDisabled={!deleteFloatingIp && !!assignedPublicIp}
            modalAction={async () => await onDelete()}
        >
            <DeleteResourceModalContent
                ipAddress={assignedPublicIp ?? undefined}
                value={deleteFloatingIp}
                onChange={(value: string) => setDeleteFloatingIp(value)}
            >
                <p>This will stop forwarding traffic to the targets and remove the load balancer.</p>
            </DeleteResourceModalContent>
        </DeleteModal>
    );
}
